<template>
    <div>
        <div class="mar-t2">
            <article>
                <select v-model="currentDate">
                    <option
                        :key="index"
                        :value="item.dateStr"
                        v-for="(item,index) in dateList" >
                        {{ item.dateStr }}
                    </option>
                </select>
            </article>
            <div>
                <h3 class="yellow">{{successNum}}</h3>
                <p>推广成功人数</p>
            </div>
            <div>
                <h3 class="green">{{totalNum}}</h3>
                <p>推广总人数</p>
            </div>
        </div>

        <div class="mar-t2">
            <h4 class="f45">
                推广记录
                <div class="ttt">
                    <select class="status-r" v-model="status">
                        <option value="">全部</option>
                        <option value="1">开通成功</option>
                        <option value="0">未开通</option>
                    </select>
                </div>
            </h4>
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getData()" class="w-100">
                <div class="data" v-for="(item,index) in list" :key="index">
                    <div class="flex">
                        <img src="/static/img/icon/icon_morentouxiang.png">
                        <p class="mar-l2 van-ellipsis">{{item.openUserName}}</p>
                    </div>
                    <p v-if="item.openVasFlag=='1'" class="fc">开通成功</p>
                    <p v-else class="red">未开通</p>
                    <p>{{item.createTime}}</p>
                </div>
            </van-list>
        </div>
    </div>
</template>

<script>
import { List } from 'vant'
export default {

    data(){
        return{
            pageSize: 10,
            pageNum: 1,
            list: [],
            finished: false,
            loading: false,
            dateList : [],
            currentDate: '',
            status: '',
            queryParam: {
                promoterId: "",
                openVasTime: "",//开通服务时间
                openVasFlag: "",//是否开通增值服务 0未开通1开通
                createTime: "",//创建时间
            },
            pageParams:{
                pageNo:1,
                pageSize:10
            },
            totalNum: 0,
            successNum: 0             
        }
    },
    created(){
        this.$emit('onActive', 1)
        this.getDateList();
        if(this.$ls.get("edCurrentUser").userPromote){
            this.queryParam.promoterId = this.$ls.get("edCurrentUser").userPromote.userId;
            //查询 推广数据列表
            this.selectPromoteRecord();
        }else{
            this.imgUrl='';
        }        
    },

    watch:{
        /**
         * @Description: 监听 日期变化
         * @Author: Niklaus
         * @Date: 2021-03-05 13:50:32
         * @param {*} v1 新数据
         * @param {*} v2 老数据
         */
        currentDate(v1,v2){
            console.log(v1,v2);
            //查询日期
            this.queryParam.createTime = v1.substring(0,4)+"-"+v1.substring(5,7)+"-01 00:00:00";
            //查询 推广数据列表
            this.list = [];
            this.pageParams.pageNo = 1;
            this.pageParams.pageSize = 10;
            this.selectPromoteRecord();
        },

        /**
         * @Description: 监听 推广纪录 状态变化
         * @Author: Niklaus
         * @Date: 2021-03-05 13:53:33
         * @param {*} v1 新数据
         * @param {*} v2 老数据
         */
        status(v1,v2){
            console.log(v1,v2);
            this.queryParam.openVasFlag = v1;
            //查询 推广数据列表
            this.list = [];
            this.pageParams.pageNo = 1;
            this.pageParams.pageSize = 10;
            this.selectPromoteRecord();            
        }
    },

    components: {
        [List.name]:List
    },

    methods: {
        /**
         * @Description: 动态获取数据
         * @Author: Niklaus
         * @Date: 2021-03-03 15:55:59
         */
        getData(){
             // for (let i = 0; i < 10; i++) {
            //     this.list.push(i)
            // }
            // if(this.list.length >= 40 ) this.finished = true
            // this.loading = false
            this.selectPromoteRecord();
        },


        /**
         * @Description: 获取日期列表：从2021年1月至现在
         * @Author: Niklaus
         * @Date: 2021-03-05 13:49:28
         */
        getDateList(){
            let nowDate =  new Date()
            if(nowDate <  new Date('2021-03-01')){
                this.toast({
                    message: '系统时间错误，请修改',
                    type: 'fail'
                })
                return
            }
            let nowYear = nowDate.getFullYear()
            let nowMonth =  nowDate.getMonth() +1
            for (let i = 2021; i <= nowYear; i++) {
                let monthNum = i < nowYear ? 12 :  nowMonth
                for (let j = 1; j <= monthNum ; j++) {
                    let monthStr = j > 9 ? j : "0" + j
                    if(i == nowYear && j == nowMonth){
                        this.currentDate = i + "年" + monthStr + "月"
                    }
                    this.dateList.push({
                        dateStr: i + "年" + monthStr + "月",
                    })
                }
            }
        },
        //查询 推广数据列表
        selectPromoteRecord(){
            var params = Object.assign({}, this.queryParam)
            params.pageNo = this.pageParams.pageNo;
            params.pageSize = this.pageParams.pageSize;
            params.openid = this.$ls.get("openid")
            let accesstoken = this.$ls.get("token")
            this.$ajax({
                methods: 'get',
                url: '/gateway/ed/wx/wxTbPromoteRecordController/list/',
                headers:{
                  'X-Access-Token':this.$ls.get("token")
                },
                params: params,
            }).then(res=>{
                if (res.data.success) {
                    let total = res.data.result.totalNum;
                    this.totalNum = total; //推广总数量
                    this.successNum = res.data.result.successNum;//推广成功数量
                    if(total>this.list.length){
                        this.list = this.list.concat(res.data.result.pageList.records);
                        this.finished = false;
                        this.pageParams.pageNo++
                    }else{
                        this.finished = true;
                    }
                    if(this.list.length == 0) this.noData = true
                    this.loading = false;
                } else {
                    this.$toast.fail("失败")
                    this.requestError = true
                    console.log(res.data.message)
                }
            });
        },
                
    }
}
</script>

<style scoped>
    .mar-t2{
        background-color: #fff;
        padding: 0.4rem;
    }
    .mar-t2:first-child>div{ width: 50%; display: inline-block; text-align: center;}
    article{text-align: center; padding-bottom: 0.3rem;}
    select{height: 0.8rem; width: 3rem; outline:none; border: none;}
    h3{font-size: 0.6rem; margin-bottom: 0.2rem;margin-top: 0.1rem;}
    .yellow{color: #FFA000;}
    .green{color: #0BBE17;}

    .data{
        display: flex; display: -webkit-flex; width: 100%; justify-content: space-between; align-items: center; margin-top: 0.3rem;
    }
    .data img{width: 1rem; height: 1rem;}
    .data>div>p{width: 2rem;}
    select:active,
    select:hover,
    select:focus,
    select:visited{
        border: black;
        box-shadow: none;
        outline: none;
    }
    .div5{width: 3rem;}
    .status-r{ background-color: #EAEBEF; color: #00A8FF; padding: 0.05rem 0.2rem; height: 0.6rem;}
    .ttt{
        display: inline-block; border-radius: 0.5rem; background-color: #EAEBEF; color: #00A8FF; padding: 0.1rem 0.2rem; margin-left: 0.2rem;
    }
</style>
